import { post } from './request'

const getMobileLoginCode = async (mobile, captcha) => {
  const res = await post('users/verifications/send_mobile_login_code', {
    mobile,
    ...captcha,
  })
  return res
}

const getMobileSignCode = async (mobile, captcha) => {
  const res = await post('users/verifications/send_mobile_registeration_code', {
    mobile,
    ...captcha,
  })
  return res
}

const sendFindPasswordCode = async (emailOrMobile, captcha) => {
  const res = await post('users/passwords/send_find_password_code', {
    email_or_mobile: emailOrMobile,
    ...captcha,
  })
  return res
}

const tencentCaptchaVerify = (afterVerify) => {
  const appId = '2045866641'
  // eslint-disable-next-line
  // @ts-ignore
  const captcha = new TencentCaptcha(appId, (res) => {
    if (res.ret === 0) {
      afterVerify(res)
    }
  })
  captcha.show()
}

const sendBindMobileCode = async (mobile, captcha) => {
  const res = await post('users/verifications/send_mobile_code', {
    mobile,
    ...captcha,
  })
  return res
}

const bindMobileByCode = async (mobile: string, code: string) => {
  const res = await post('users/verifications/bind_mobile_by_code', { mobile, code })
  return res
}

export { getMobileLoginCode, getMobileSignCode, sendFindPasswordCode, tencentCaptchaVerify, sendBindMobileCode, bindMobileByCode }
